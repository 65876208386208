<div class="video-container" [ngClass]="{ 'fut-skeleton': state() === 'LOADING', completed: completed() }">
  <div #videoCover (mouseleave)="geometryPositionHover(videoCover)" [ngClass]="category()" class="live-container">
    <div class="d-flex flex-column live-text" [style.--video-cover-width.px]="videoCoverWidth()">
      <span class="live-title" *futGhostStateFragment="state(); textMinChar: 8; textMaxChar: 15">{{ videoTitle() }}</span>
      <span [innerHTML]="videoSubtitle()" class="live-subtitle fut-bold" *futGhostStateFragment="state(); textMinChar: 10; textMaxChar: 30"></span>
    </div>

    <div class="live-square"></div>
    <img alt="triangle-img" class="live-triangle" src="/assets/triangle.svg" />
    <div class="live-circle"></div>
    @if (state() !== 'LOADING' && duration() && duration() >= 0) {
      <div class="duration">{{ durationToString() }}</div>
    }
    @if (completed()) {
      <div class="completed-opacity"></div>
    }
    @if (state() !== 'LOADING') {
      <div class="live-play">
        @if (!completed()) {
          <mat-icon fontSet="material-symbols-outlined">play_arrow</mat-icon>
        } @else {
          <mat-icon fontSet="material-symbols-outlined">check</mat-icon>
        }
      </div>
    }
  </div>
</div>
