import { inject, Signal } from '@angular/core';
import { ConfigService } from './config.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';

export function showCat2(): Signal<boolean> {
  const configService = inject(ConfigService);
  return toSignal(configService.getUiConfig('_hide_cat2').pipe(map(hide => !hide)), { initialValue: false });
}

export function showDifficultySelector(): Signal<boolean> {
  const configService = inject(ConfigService);
  return toSignal(configService.getUiConfig('_show_difficulty_selector').pipe(map(show => show === 'true')), { initialValue: false });
}
