import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-ghost-overlay',
  templateUrl: './ghost-overlay.component.html',
  styleUrls: ['./ghost-overlay.component.scss'],
})
export class GhostOverlayComponent {
  @Input() public icon: string | null = 'lock';
  @Input() public fontSet = 'material-symbols-outlined';

  @Input() public ghostTitle?: string;
  @Input() public titleClass?: string;
  @Input() public content?: string;
  @Input() public contentClass?: string;

  @Input() public actionButton?: string;
  @Input() public menuTemplate?: TemplateRef<unknown>;

  @Output() public readonly ghostClick = new EventEmitter<void>();
}
