import { VideoCategory } from '../interfaces/video-category';
import { Meeting } from '../interfaces/meeting';
import { upperCaseFromUnderscore } from './string.util';

export function isVideoCategory(media: VideoCategory | Meeting): media is VideoCategory {
  return (media as VideoCategory).video_uuid !== undefined;
}

export function keywordsFormatter(keywords: Array<string>): Array<string> {
  return keywords.map(keyword => upperCaseFromUnderscore(keyword));
}
