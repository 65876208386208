import { Question, QuestionUserBehaviour, TestQuestion } from 'src/app/core/interfaces/question';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'app-long-text-view-dialog',
  templateUrl: './long-text-view-dialog.component.html',
  styleUrls: ['./long-text-view-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatIcon, MatDialogClose],
})
export class LongTextViewDialogComponent implements AfterViewInit {
  content?: HTMLElement;

  long_text: string = '';

  // generate compontent id
  readonly id = 'id-' + Math.random().toString(36).substring(2);

  constructor(@Inject(MAT_DIALOG_DATA) public data: { question: Question | QuestionUserBehaviour | TestQuestion }) {
    this.long_text = (this.isQuestion(data.question) ? data.question.content.long_text : data.question.long_text) || '';
  }

  isQuestion(question: any): question is Question {
    return (question as Question).content !== undefined;
  }

  ngAfterViewInit(): void {
    this.content = document.getElementById(this.id)!;
  }

  scrollTop() {
    if (this.content) this.content.scrollTo({ top: 0, behavior: 'smooth' });
  }

  scrollBottom() {
    if (this.content) this.content.scrollTo({ top: this.content.scrollHeight * 2, behavior: 'smooth' });
  }
}
