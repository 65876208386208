import { Component, effect, ElementRef, HostListener, input, model, signal, viewChild } from '@angular/core';
import { Meeting } from '../../../core/interfaces/meeting';
import { VideoCategory } from '../../../core/interfaces/video-category';
import { categoryPathToCategory, pathToCategories } from '../../../core/util/category.util';
import { isVideoCategory } from '../../../core/util/video.util';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { COMPONENT_STATE, ComponentState, GhostStateFragmentDirective } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-video-cover',
  templateUrl: './video-cover.component.html',
  styleUrls: ['./video-cover.component.scss'],
  standalone: true,
  imports: [MatIcon, NgClass, GhostStateFragmentDirective],
})
export class VideoCoverComponent {
  public readonly videoTitle = model<string>();
  public readonly videoSubtitle = model<string>();
  public readonly category = model<string>('');
  public readonly duration = model<number>(0);

  public readonly state = input<ComponentState>(COMPONENT_STATE.NONE);
  public readonly completed = input<boolean>(false);
  public readonly media = input<VideoCategory | Meeting>();
  private readonly videoCover = viewChild<ElementRef<HTMLElement>>('videoCover');

  public readonly videoCoverWidth = signal<number>(0);

  durationToString() {
    const _duration = this.duration();
    if (_duration <= 0) return '';
    if (_duration) {
      const hours = Math.floor(_duration / 3600);
      const minutes = Math.floor((_duration % 3600) / 60);
      const seconds = _duration % 60;
      return `${hours ? hours + ':' : ''}${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }
    return '';
  }

  constructor() {
    effect(
      () => {
        const media = this.media();
        if (!media) {
          return;
        }
        this.initComponentWithMedia(media);
      },
      { allowSignalWrites: true }
    );

    effect(
      () => {
        const videoCover = this.videoCover();
        if (!videoCover) {
          return;
        }
        this.changeFontSize();
        this.customGeometry();
      },
      { allowSignalWrites: true }
    );
  }

  @HostListener('window:resize')
  public onResize(): void {
    this.changeFontSize();
  }

  public geometryPositionHover(cover: HTMLElement): void {
    const square_translate_now = +cover?.style.getPropertyValue('--square-translate').replace('%', '');

    const square_translate_x = this.addNoise(square_translate_now, 15);
    const square_translate_y = this.addNoise(0, 15);

    const triangle_translate_now = +cover?.style.getPropertyValue('--triangle-translate').replace('%', '');

    const triangle_translate_x = this.addNoise(triangle_translate_now, 15);
    const triangle_translate_y = this.addNoise(0, 15);

    const circle_translate_x = this.addNoise(0, 15);
    const circle_translate_y = this.addNoise(0, 15);

    cover.style.setProperty('--square-translate-x', square_translate_x + '%');
    cover.style.setProperty('--square-translate-y', square_translate_y + '%');

    cover.style.setProperty('--triangle-translate-x', triangle_translate_x + '%');
    cover.style.setProperty('--triangle-translate-y', triangle_translate_y + '%');

    cover.style.setProperty('--circle-translate-x', circle_translate_x + '%');
    cover.style.setProperty('--circle-translate-y', circle_translate_y + '%');
  }

  private initComponentWithMedia(media: VideoCategory | Meeting): void {
    let title: string;
    let subtitleBuilder: Array<string>;
    let duration: number;
    if (isVideoCategory(media)) {
      [title, subtitleBuilder, duration] = this.initFromVideoCategory(media);
    } else {
      [title, subtitleBuilder, duration] = this.initFromMeeting(media);
    }

    const subtitle = this.createSubtitle(subtitleBuilder, this.category());

    this.videoTitle.set(categoryPathToCategory(title));
    this.videoSubtitle.set(subtitle?.length ? subtitle.join('<br />') : '');
    this.duration.set(duration);
  }

  private initFromVideoCategory(media: VideoCategory): [string, Array<string>, number] {
    const title = pathToCategories(media.category_path)[0];
    const subtitle = [media.category_path];
    this.category.set(media.category_path.split('.')[0]);
    const duration = media.duration * 60;
    return [title, subtitle, duration];
  }

  private initFromMeeting(media: Meeting): [string, Array<string>, number] {
    const title = pathToCategories(media.content.categories[0])[0] || '';
    const subtitle = [...media.content.categories];
    this.category.set(media.content.categories[0].split('.')[0]);
    let duration = media.content.status.duration * 60;

    const startTime = media.content.status.started_at || media.content.start_time;

    if (startTime && media.content.status.completed_at) {
      const calcDuration = Math.floor((+media.content.status.completed_at - startTime) / 1000);
      duration = calcDuration || duration;
    }

    return [title, subtitle, duration];
  }

  private createSubtitle(subtitleBuilder: Array<string>, category: string): Array<string> {
    return Array.from(
      new Set(
        subtitleBuilder
          .filter(subtitle => subtitle != category)
          .map(subtitle => categoryPathToCategory(subtitle.split('.').reverse()[0] || '').trim())
          .filter(category => category)
      )
    ).splice(0, 7);
  }

  private changeFontSize(): void {
    const cover = this.getNativeElement();
    if (!cover) {
      return;
    }
    const width = cover.clientWidth;
    this.videoCoverWidth.set(width);
  }

  private customGeometry(): void {
    const cover = this.getNativeElement();
    if (!cover) {
      return;
    }
    const circle_size = this.addNoise(35, 20);
    const triangle_size = this.addNoise(40, 10);
    const square_size = this.addNoise(25, 10);

    const circle_opacity = this.addNoise(0.6, 0.2, 0.1);
    const triangle_opacity = this.addNoise(0.4, 0.3, 0.35);
    const square_opacity = this.addNoise(0.4, 0.3, 0.35);

    const square_rotate = this.addNoise(15, 15);
    const square_translate = this.addNoise(70, 25);

    const triangle_translate = this.addNoise(-40, 25);

    cover.style.setProperty('--circle-size', circle_size + '%');
    cover.style.setProperty('--triangle-size', triangle_size + '%');
    cover.style.setProperty('--square-size', square_size + '%');

    cover.style.setProperty('--circle-opacity', circle_opacity + '');
    cover.style.setProperty('--triangle-opacity', triangle_opacity + '');
    cover.style.setProperty('--square-opacity', square_opacity + '');

    cover.style.setProperty('--square-rotate', square_rotate + 'deg');
    cover.style.setProperty('--square-translate', square_translate + '%');

    cover.style.setProperty('--triangle-translate', triangle_translate + '%');

    this.geometryPositionHover(cover);
  }

  private getNativeElement() {
    return this.videoCover()?.nativeElement;
  }

  private addNoise(value: number, noiseSize: number, min = -Infinity): number {
    return Math.max(value + (Math.random() * noiseSize - noiseSize / 2), min);
  }
}
