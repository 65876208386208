import { AliceGenerator, Question, TestQuestion } from '../interfaces/question';
import { QuestionUserBehaviour } from 'src/app/core/interfaces/question';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiUrl, createCustomHeader } from '../util/http.util';

@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  constructor(private http: HttpClient) {}

  _isQuestion(question: Question | QuestionUserBehaviour): question is Question {
    return (question as Question).content !== undefined;
  }

  getExplanation(question: Question | QuestionUserBehaviour | TestQuestion) {
    let alice_generator: AliceGenerator | undefined;
    let explanation: string;

    if (this._isQuestion(question)) {
      alice_generator = question.content.alice_generator;
      explanation = question.content.explanation;
    } else {
      alice_generator = question.alice_generator;
      explanation = question.explanation;
    }

    if (explanation) return explanation;
    if (alice_generator && alice_generator.explanation) return alice_generator.explanation;

    return '';
  }

  public getQuestionCount(): Observable<{ [key: string]: number }> {
    return this.http
      .get<Array<string>>(
        apiUrl + '/questions/get_cat_paths',
        createCustomHeader({
          custom_params: { Typeresult: 'raw' },
        })
      )
      .pipe(
        map(categories => {
          const question_count: { [key: string]: number } = {};
          categories.forEach(category => {
            if (question_count[category] == undefined) question_count[category] = 0;
            question_count[category]++;
          });
          return question_count;
        })
      );
  }

  public getQuestionByUuid(questionUuid: string): Observable<Question> {
    return this.http
      .get<Array<Question>>(
        apiUrl + '/questions/get_questions',
        createCustomHeader({
          custom_params: { Questions: questionUuid },
        })
      )
      .pipe(map(questions => questions[0]));
  }
}
