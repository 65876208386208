<fut-base-dialog dialogTitle="simulation.extra.custom.title">
  <div class="pt-2">
    <div class="w-100 mb-3">{{ 'simulation.extra.custom.subtitle' | transloco }}</div>
    <mat-form-field subscriptSizing="dynamic" appearance="outline">
      <mat-label>{{ 'simulation.extra.custom.simulation_name' | transloco }}</mat-label>
      <input matInput [(ngModel)]="custom_simulation_name" type="text" />
    </mat-form-field>
    @if (custom_simulations_option.length !== 0) {
      <hr class="w-100" />
    }
    @for (info of custom_simulations_option; track info; let i = $index) {
      <div @removeItem class="row mx-0 mb-3 py-4 py-lg-2 bg-quaternary rounded" appGhostOverlay [state]="state">
        <div class="col-12 mb-4 mt-3" [ngClass]="{ 'col-lg-12': showCat2Filter() }">
          <mat-form-field subscriptSizing="dynamic" appearance="outline">
            <mat-label>{{ 'simulation.extra.custom.category' | transloco }}</mat-label>
            <mat-select [(ngModel)]="info.cat1" (change)="onCat1Change($event, info)">
              @for (cat of cat1; track cat) {
                <mat-option [value]="cat">{{ displayCategory(cat) }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        @if (showCat2Filter()) {
          <div class="col-12 mb-4">
            <mat-form-field subscriptSizing="dynamic" appearance="outline">
              <mat-label>{{ 'simulation.extra.custom.sub_cat' | transloco }}</mat-label>
              <mat-select [(ngModel)]="info.cat2" [disabled]="info.cat1 === ''">
                <mat-option value="">{{ 'simulation.extra.custom.all_topic' | transloco }}</mat-option>
                @for (cat of getCats2(info.cat1 || ''); track cat) {
                  <mat-option [value]="cat">{{ displayCategory(cat) }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
        }
        @if (showDifficultySelector()) {
          <div class="col-12 mt-3 mb-4">
            <mat-form-field subscriptSizing="dynamic" appearance="outline">
              <mat-label>{{ 'simulation.extra.custom.difficulty' | transloco }}</mat-label>
              <mat-select [(ngModel)]="info.difficulty" [disabled]="info.cat1 === ''">
                <mat-option [value]="0">{{ 'difficulty.label.auto' | transloco }}</mat-option>
                <mat-option [value]="1">{{ 'difficulty.label.veryEasy' | transloco }}</mat-option>
                <mat-option [value]="2">{{ 'difficulty.label.easy' | transloco }}</mat-option>
                <mat-option [value]="3">{{ 'difficulty.label.medium' | transloco }}</mat-option>
                <mat-option [value]="4">{{ 'difficulty.label.difficult' | transloco }}</mat-option>
                <mat-option [value]="5">{{ 'difficulty.label.veryDifficult' | transloco }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        }
        <div class="col-12">
          <label>{{ 'simulation.extra.custom.question' | transloco }}</label>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="w-100 d-flex justify-content-center justify-content-lg-start align-items-center">
            <button
              class="fut-btn fut-btn-tertiary fut-btn-pill"
              (click)="info.question_number = min((info.question_number || 0) - 1, getCategoryQuestionCount(info.cat1 + '.' + info.cat2))"
              [disabled]="info.question_number === 1">
              <mat-icon fontSet="material-symbols-outlined">remove</mat-icon>
            </button>
            <span class="font-weight-bold text-center" style="font-size: 1.4em; min-width: 2.5em">{{
              max(min(info.question_number || 0, getCategoryQuestionCount(info.cat1 + '.' + info.cat2)), 0)
            }}</span>
            <button
              class="fut-btn fut-btn-tertiary fut-btn-pill"
              (click)="info.question_number = min((info.question_number || 0) + 1, getCategoryQuestionCount(info.cat1 + '.' + info.cat2))"
              [disabled]="(info.question_number || 0) >= 20 || (info.question_number || 0) === getCategoryQuestionCount(info.cat1 + '.' + info.cat2)">
              <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
            </button>
          </div>
        </div>
        <div class="col-12 d-block d-lg-none mb-3 text-center">
          @if (getCategoryQuestionCount(info.cat1 + '.' + info.cat2) === (info.question_number || 0)) {
            <small class="fut-text-error text-center d-block" style="line-height: 1.2em">
              Hai raggiunto il numero massimo di domande per questo argomento.
            </small>
          }
        </div>
        <div class="col-12 col-lg-6">
          <button class="fut-btn fut-btn-tertiary fut-btn-icon w-100" (click)="removeSection(i)">
            <mat-icon fontSet="material-symbols-outlined">delete</mat-icon>
            <span>{{ 'simulation.extra.custom.remove_section' | transloco }}</span>
          </button>
        </div>
        <div class="col-12 d-none d-lg-block">
          @if (getCategoryQuestionCount(info.cat1 + '.' + info.cat2) === (info.question_number || 0)) {
            <small class="fut-text-error"> Hai raggiunto il numero massimo di domande per questo argomento. </small>
          }
        </div>
      </div>
    }
  </div>

  <ng-container actions>
    <button (click)="addCategory()" [disabled]="custom_simulations_option.length >= MAX_SECTIONS_COUNT" class="fut-btn fut-btn-tertiary w-100 mt-2">
      @if (custom_simulations_option.length < MAX_SECTIONS_COUNT) {
        <span>{{ 'simulation.extra.custom.add_section' | transloco }}</span>
      }
      @if (custom_simulations_option.length >= MAX_SECTIONS_COUNT) {
        <span>{{ 'simulation.extra.custom.errors.too_many_sections' | transloco }} ({{ MAX_SECTIONS_COUNT }}).</span>
      }
    </button>

    @if (custom_simulations_option.length !== 0) {
      <hr class="w-100" />
      <div class="d-flex flex-column mb-4">
        <div class="font-weight-bold w-100 d-flex justify-content-between align-items-center">
          <span class="font-weight-normal">{{ 'simulation.extra.custom.info.question_lenght' | transloco }} </span
          ><span [ngClass]="{ 'fut-text-error': questionCount() > MAX_QUESTIONS_COUNT }">{{ questionCount() }}</span>
        </div>
        <div class="font-weight-bold w-100 d-flex justify-content-between align-items-center">
          <span class="font-weight-normal">{{ 'simulation.extra.custom.info.time' | transloco }} </span>{{ getTime() }}
        </div>
      </div>
    }

    <div class="d-flex justify-content-end align-items-center" mat-dialog-actions>
      <button (click)="create()" [disabled]="state !== 'NONE'" class="fut-btn fut-btn-accent">
        <span>{{ 'dashboard.buttons.start_simulation' | transloco }}</span>
        <!-- <div class="spinner-border" role="status" *ngIf="state === ComponentState.LOADING">
        <span class="sr-only">Loading...</span>
      </div> -->
      </button>
    </div>
  </ng-container>
</fut-base-dialog>
