<div class="d-flex justify-content-end align-items-center" mat-dialog-title></div>

<div [id]="id" [innerHTML]="long_text" class="py-2" mat-dialog-content></div>

<div class="d-flex justify-content-between align-items-center" mat-dialog-actions>
  <div class="d-flex align-items-center">
    <button (click)="scrollBottom()" class="fut-btn fut-btn-outline fut-btn-pill mr-4">
      <mat-icon fontSet="material-symbols-outlined" style="transform: rotate(90deg)">skip_next</mat-icon>
    </button>
    <button (click)="scrollTop()" class="fut-btn fut-btn-outline fut-btn-pill">
      <mat-icon fontSet="material-symbols-outlined" style="transform: rotate(270deg)">skip_next</mat-icon>
    </button>
  </div>
  <button class="fut-btn fut-btn-outline fut-btn-pill" mat-dialog-close>
    <mat-icon fontSet="material-symbols-outlined">fullscreen_exit</mat-icon>
  </button>
</div>
