import { VideoCategory } from '../../../core/interfaces/video-category';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Meeting } from 'src/app/core/interfaces/meeting';
import { Component, ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { FrameVimeoLiveComponent } from '../../components/frame-vimeo-live/frame-vimeo-live.component';

@Component({
  selector: 'app-view-live-recording-dialog',
  templateUrl: './view-live-recording-dialog.component.html',
  styleUrls: ['./view-live-recording-dialog.component.scss'],
  standalone: true,
  imports: [FrameVimeoLiveComponent],
})
export class ViewLiveRecordingDialogComponent {
  @ViewChild('videoFrame', { read: ElementRef }) private videoFrame?: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { live?: Meeting; video?: VideoCategory },
    @Optional() private dialogRef: MatDialogRef<ViewLiveRecordingDialogComponent>
  ) {}

  public onClickElement(event: MouseEvent): void {
    const contains = this.videoFrame?.nativeElement?.contains(event.target);
    if (contains) {
      return;
    }
    this.dialogRef?.close();
  }
}
