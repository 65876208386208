<div class="ghost-overlay px-2">
  @if (icon && icon !== 'loading') {
    <mat-icon [fontSet]="fontSet">{{ icon }}</mat-icon>
  }
  @if (icon && icon === 'loading') {
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  }
  @if (ghostTitle) {
    <p [innerHTML]="ghostTitle | transloco" class="mt-4 ghost-title {{ titleClass || '' }}" [ngClass]="{ 'mb-4': !content }"></p>
  }
  @if (content) {
    <span [class]="contentClass" [innerHTML]="content | transloco"></span>
  }
  @if (actionButton) {
    @if (!menuTemplate) {
      <button class="fut-btn fut-btn-outline" (click)="ghostClick.emit()">
        {{ actionButton | transloco }}
      </button>
    }
    @if (menuTemplate) {
      <button class="fut-btn fut-btn-outline btn-action-menu" [matMenuTriggerFor]="menu">
        <span>{{ actionButton | transloco }}</span>
        <mat-icon fontSet="material-symbols-outlined"> arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-template matMenuContent>
          <fut-button-menu-content>
            <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
          </fut-button-menu-content>
        </ng-template>
      </mat-menu>
    }
  }
</div>
