import { MatDialog } from '@angular/material/dialog';
import { ViewLiveRecordingDialogComponent } from '../../shared/dialogs/view-live-recording-dialog/view-live-recording-dialog.component';
import { VideoCategory } from '../interfaces/video-category';
import { environment } from '../../../environments/environment';
import { FutProfile } from '../interfaces/profile';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { createCustomHeader } from '../util/http.util';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  constructor(
    private http: HttpClient,
    private dialog: MatDialog
  ) {}

  // Observable
  public getVideos(): Observable<Array<VideoCategory>> {
    return this.http.get<Array<VideoCategory>>(environment.be_url + '/profiles/videos');
  }

  public all(): Observable<Array<string>> {
    return this.http.get<Array<string>>(environment.be_url + '/questions/get_cat_paths');
  }

  public create(profile: FutProfile): Observable<FutProfile> {
    return this.http.put<FutProfile>(environment.be_url + '/profiles/', profile.content);
  }

  public delete(profile: FutProfile): Observable<void> {
    return this.http.delete<void>(
      environment.be_url + '/profiles/',
      createCustomHeader({
        target_resource: profile.instance_id,
      })
    );
  }

  public update(profile: FutProfile): Observable<FutProfile> {
    return this.http.post<FutProfile>(
      environment.be_url + '/profiles/',
      { content: profile.content },
      createCustomHeader({
        target_resource: profile.instance_id,
      })
    );
  }

  public openVideoDialog(video: VideoCategory) {
    return this.dialog.open(ViewLiveRecordingDialogComponent, {
      data: { video },
      width: '90vw',
      height: '70vh',
      panelClass: 'fut-mat-dialog-transparent',
    });
  }

  getDuration(group_by: undefined): Observable<number>;
  getDuration(group_by: 'cat1' | 'cat2' | 'cat3' | 'all'): Observable<{ [key: string]: number }>;
  getDuration(group_by?: 'cat1' | 'cat2' | 'cat3' | 'all' | undefined):
    | Observable<number>
    | Observable<{
        [key: string]: number;
      }> {
    if (group_by)
      return this.http.get<{
        [key: string]: number;
      }>(
        environment.be_url + '/stats/course_duration',
        createCustomHeader({
          custom_params: { 'Group-By': group_by },
        })
      );
    else return this.http.get<number>(environment.be_url + '/stats/course_duration');
  }
}
