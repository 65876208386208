import { Question, QuestionUserBehaviour, TestQuestion } from '../interfaces/question';
import { LongTextViewDialogComponent } from '../../shared/dialogs/long-text-view-dialog/long-text-view-dialog.component';
import { CustomSimulationSingleInfo } from '../../shared/dialogs/custom-simulation-dialog/custom-simulation-dialog.component';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Cat2NewTestDialogComponent } from 'src/app/shared/dialogs/cat2-new-test-dialog/cat2-new-test-dialog.component';
import { CustomSimulationDialogComponent } from 'src/app/shared/dialogs/custom-simulation-dialog/custom-simulation-dialog.component';
import { BasicSimulationInfo } from '../interfaces/basic-simulation';
import { SimulationHandlerAction } from '../interfaces/simulation';
import { UtilsService } from '../utils-service/utils.service';
import { FutDialogService } from '../dialog-service/fut-dialog.service';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { apiUrl, createCustomHeader } from '../util/http.util';
import { ConfigService } from '../config/config.service';
import { Test } from '../interfaces/test';

export type QuestionPoint = { correct: number; wrong: number; blank: number };

@Injectable({
  providedIn: 'root',
})
export class SimulationService {
  forced_update = false;

  simulations_loaded = false;

  onSimulationsLoaded = new EventEmitter<Array<BasicSimulationInfo>>();

  DEFAULT_DURATION_TEST = 0;
  DEFAULT_QUESTION_COUNT_TEST = 0;

  private questionPointType?: QuestionPoint;

  constructor(
    private http: HttpClient,
    private utilsService: UtilsService,
    private dialog: FutDialogService,
    configService: ConfigService
  ) {
    configService.getSimulationConfig('_DEFAULT_QUESTION_COUNT_TEST').subscribe(count => (this.DEFAULT_QUESTION_COUNT_TEST = count));
    configService.getSimulationConfig('_DEFAULT_DURATION_TEST').subscribe(duration => (this.DEFAULT_DURATION_TEST = duration));
  }

  public getAllCategories(): Observable<Array<string>> {
    return this.http.get<Array<string>>(apiUrl + '/questions/get_cat_paths');
  }

  public getPoints(): Observable<QuestionPoint> {
    if (this.questionPointType) {
      return of({ ...this.questionPointType });
    }
    return this.http.get<QuestionPoint>(apiUrl + '/config/question_point').pipe(tap(questionPointType => (this.questionPointType = questionPointType)));
  }

  public getCompletedTests(): Observable<Array<BasicSimulationInfo>> {
    // Permette di avere tutte le simulazioni completate fatte dall'utente
    // Resituite una promessa con:
    // Successo se sono stata prese tuttle le informazioni su tutte le Simulazione
    // Errore l'utente non è loggato o un errore del server
    if (!this.forced_update && localStorage.getItem('tests_list') != undefined) {
      const simuations = JSON.parse(localStorage.getItem('tests_list')!) as Array<BasicSimulationInfo>;
      return of(simuations);
    }
    const to_return = new Array<BasicSimulationInfo>();
    return this.http.get<Array<BasicSimulationInfo>>(apiUrl + '/simulations/').pipe(
      map(simulations => {
        for (const simulation of simulations) {
          if (simulation.category_path != '.') {
            simulation.category_details = this.utilsService.getMilestoneFromName(simulation.category_path);
            to_return.push(simulation);
          }
        }
        this.forced_update = false;
        to_return.map(test => (test.category_details = this.utilsService.getMilestoneFromName(test.category_path)));
        localStorage.setItem('tests_list', JSON.stringify(to_return));
        return to_return;
      }),
      catchError(() => of(new Array<BasicSimulationInfo>()))
    );
  }

  public getAllCompleted(): Observable<Array<BasicSimulationInfo>> {
    return forkJoin([this.getCompletedTests(), this.getCompletedSimulations()]).pipe(map(([tests, simulations]) => simulations.concat(tests)));
  }

  public getCompletedSimulations(): Observable<Array<BasicSimulationInfo>> {
    // Permette di avere tutte le simulazioni completate fatte dall'utente
    // Resituite una promessa con:
    // Successo se sono stata prese tuttle le informazioni su tutte le Simulazione
    // Errore l'utente non è loggato o un errore del server
    if (!this.forced_update && localStorage.getItem('simulations_list') != undefined) {
      const simulations = JSON.parse(localStorage.getItem('simulations_list')!) as Array<BasicSimulationInfo>;
      this.simulations_loaded = true;
      this.onSimulationsLoaded.next(simulations);
      return of(simulations);
    }
    return this.http.get<Array<BasicSimulationInfo>>(apiUrl + '/simulations/').pipe(
      tap(simulations => {
        localStorage.setItem('simulations_list', JSON.stringify(simulations));
        this.forced_update = false;
        this.simulations_loaded = true;
        this.onSimulationsLoaded.next(simulations);
      }),
      catchError(err => {
        // Errore generico del server

        // Nessuna simulazione trovata
        if (err.status == 404) {
          this.simulations_loaded = true;
          this.onSimulationsLoaded.next(new Array<BasicSimulationInfo>());
          return of(new Array<BasicSimulationInfo>());
        } else {
          return throwError(() => new Array<BasicSimulationInfo>());
        }
      })
    );
  }

  public reportQuestion(question: QuestionUserBehaviour, report_reason: Array<string>): Observable<void>;
  public reportQuestion(question: Question, report_reason: Array<string>): Observable<void>;
  public reportQuestion(question: Question | QuestionUserBehaviour, report_reason: Array<string>): Observable<void> {
    const resource_uid = (question as QuestionUserBehaviour).id ? (question as QuestionUserBehaviour).id : (question as Question).resource_uid;
    const is_flagged_as_wrong = (question as QuestionUserBehaviour).id
      ? (question as QuestionUserBehaviour).is_flagged_as_wrong
      : (question as Question).content.user_behaviour.is_flagged_as_wrong;
    return this.reportQuestionByUuid(resource_uid, is_flagged_as_wrong, report_reason);
  }

  public reportQuestionByUuid(id_question: string | number, flaggedAsWrong: boolean, reason: Array<string>): Observable<void> {
    return this.http.post<void>(apiUrl + '/questions/report', {
      target_type: 'question',
      id_question,
      increment_value: flaggedAsWrong ? 1 : -1,
      reason,
    });
  }

  public showCat2Dialog(category_name: string, mode?: string, type?: string): void {
    this.dialog.open(Cat2NewTestDialogComponent, {
      dimension: 'md',
      matConf: { data: { cat1: category_name, mode: mode, type: type } },
    });
  }

  public shareSimulation(simulation: string, target_user = 'self'): Observable<{ url: string; token: string }> {
    return this.http
      .get<{
        url: string;
        token: string;
      }>(
        apiUrl + '/simulations/share',
        createCustomHeader({
          target_user,
          target_resource: simulation,
          custom_params: { Url: location.origin },
        })
      )
      .pipe(tap(simulation_jwt => console.trace(simulation_jwt.url)));
  }

  public customSimulationDialog(data?: Array<CustomSimulationSingleInfo>): Observable<Test> {
    return new Observable<Test>(observer => {
      this.dialog
        .open<CustomSimulationDialogComponent, Array<CustomSimulationSingleInfo>, Test>(CustomSimulationDialogComponent, {
          dimension: 'md',
          matConf: { data, backdropClass: 'dialog-mobile-no-padding' },
        })
        .afterClosed()
        .subscribe({
          next: (test?: Test) => {
            if (test) observer.next(test);
            observer.complete();
          },
        });
    });
  }

  public getActionChild(actions: Array<SimulationHandlerAction> | undefined, pick_only_choice_chose = false): Array<SimulationHandlerAction> {
    const actions_to_return = actions!;
    actions!.forEach(action => {
      if (action.type == 'choice' && action.choice!.choice != undefined) {
        if (pick_only_choice_chose)
          actions_to_return.push(...this.getActionChild(action.choice!.choice! ? action.choice!.actions_true : action.choice!.actions_false));
        else {
          actions_to_return.push(...this.getActionChild(action.choice!.actions_true));
          actions_to_return.push(...this.getActionChild(action.choice!.actions_false));
        }
      }
    });
    return actions_to_return;
  }

  public questionLongTextView(question: Question | QuestionUserBehaviour | TestQuestion): void {
    this.dialog.open(LongTextViewDialogComponent, {
      dimension: 'md',
      matConf: {
        data: { question },
        backdropClass: 'dialog-mobile-no-padding',
        height: 'min-content',
      },
    });
  }
}
