<div class="w-100 d-flex justify-content-between align-items-center">
  <h2>{{ upperCase(data.cat1) }}</h2>
  <mat-icon fontSet="material-symbols-outlined" (click)="close()" class="pointer">close</mat-icon>
</div>

<p>{{ 'dialogs.new_test.sub_cat.title' | transloco }}</p>
<div class="row pb-1">
  <mat-form-field appearance="outline" [ngClass]="{ 'col-lg-12': data.mode != undefined, 'col-lg-8': data.mode == undefined }" class="col-12 pr-lg-0">
    <mat-select [(value)]="catSelected" (selectionChange)="setupCount()" class="w-100">
      <!--  multiple> -->
      <mat-option [value]="data.cat1">{{ 'dialogs.new_test.sub_cat.test_of' | transloco: { subject: upperCase(data.cat1) } }} </mat-option>
      @if (showCat2Filter()) {
        @for (cat2 of cats2; track cat2) {
          <mat-option class="cat_value" [value]="data.cat1 + '.' + cat2">{{ upperCase(cat2) }}</mat-option>
        }
      }
    </mat-select>
  </mat-form-field>
  @if (data.mode == undefined) {
    <mat-form-field appearance="outline" class="col-12 col-lg-4">
      <mat-select [(value)]="countSelected" class="w-100">
        @for (count of questions_count_dropdown; track count) {
          <mat-option [value]="count">
            {{ 'dialogs.new_test.sub_cat.count_question' | transloco: { len: count } }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }
</div>

<div class="d-flex w-100 justify-content-end">
  <button (click)="start()" class="fut-btn fut-btn-accent">{{ 'dialogs.new_test.sub_cat.start' | transloco }}</button>
</div>
