import { upperCaseFromUnderscore } from './string.util';
import { Meeting } from '../interfaces/meeting';

export function categoryPathToCategory(value?: string): string {
  if (!value) {
    return value || '';
  }
  const capitalize = value.charAt(0).toUpperCase() + value.slice(1);

  return capitalize.split('_').join(' ');
}

export function pathToCategories(categoryPath: string): [cat1: string, cat2: string | undefined, cat3: string | undefined] {
  const categories = categoryPath.split('.');
  return [categories[0], categories[1], categories[2]];
}

export function getCatDisplayName(from: string, cat_n = 1): string {
  let target = from.split('.')[cat_n - 1];
  target = target.split('_').join(' ');
  return upperCaseFromUnderscore(target);
}

export function getLiveCategory(live: Meeting) {
  return live.content.categories[0] || '';
}
