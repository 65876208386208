import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GhostOverlayComponent } from './component/ghost-overlay/ghost-overlay.component';
import { GhostOverlayDirective } from './directive/ghost-overlay/ghost-overlay.directive';
import { TranslocoModule } from '@jsverse/transloco';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonMenuContentComponent } from '@futura/futura-ui/button';

@NgModule({
  declarations: [GhostOverlayComponent, GhostOverlayDirective],
  imports: [CommonModule, TranslocoModule, MatIconModule, MatMenuModule, ButtonMenuContentComponent],
  exports: [GhostOverlayComponent, GhostOverlayDirective],
})
export class GhostOverlayModule {}
