import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Archive } from '../../../../shared/category/model/category.model';
import { apiUrl } from '../../../../core/util/http.util';

@Injectable({ providedIn: 'root' })
export class ArchiveService {
  constructor(private http: HttpClient) {}

  public getCategories(): Observable<Array<Archive>> {
    return this.http.get<Array<Archive>>(apiUrl + '/data/categories');
  }

  public updateCategories(): Observable<Array<Archive>> {
    return this.http.post<Array<Archive>>(apiUrl + '/data/categories', {});
  }
}
